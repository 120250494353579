import { useState } from 'react';
import { AppRegistration, Login, Password } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

export function Navigation(props) {
    const theme = useTheme();
    const [hoverOn, setHoverOn] = useState(false);

    const menuItems = [
        { icon: <Login />, text: 'Bejelentkezés', link: '/login', },
        { icon: <AppRegistration />, text: 'Regisztráció', link: '/registration', },
        { icon: <Password />, text: 'Elfelejtett jelszó', link: '/forgotPassword', },
    ];
    const menuItemHeight = 40;

    const openedMixin = () => ({
        marginTop: props.headerHeight + 'px',
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp,
        }),
        width: props.navigationWidth,
    });

    const closedMixin = () => ({
        marginTop: props.headerHeight + 'px',
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
        width: 'calc(' + theme.spacing(7) + ' + 1px)',
        [theme.breakpoints.up('md')]: {
            width: 'calc(' + theme.spacing(8) + ' + 1px)',
        },
    });

    function handleNavigation() {
        if (!props.navigationOpen) {
            props.setNavigationOpen(true);
            setHoverOn(true);
        }
        else {
            if (hoverOn) {
                props.setNavigationOpen(false);
            }
            setHoverOn(false);
        }
    }

    return (
        <MuiDrawer
            onMouseEnter={handleNavigation}
            onMouseLeave={handleNavigation}
            open={props.navigationOpen}
            sx={{
                boxSizing: 'border-box',
                flexShrink: 0,
                whiteSpace: 'nowrap',
                width: props.navigationWidth,
                ...(props.navigationOpen && {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                }),
                ...(!props.navigationOpen && {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                }),
            }}
            variant="permanent"
        >
            <List disablePadding>
                {
                    menuItems.map(
                        item => (
                            <ListItem component="a" disablePadding href={item.link} key={item.text} style={{ color: theme.palette.common.white, }}>
                                <ListItemButton
                                    sx={{
                                        justifyContent: props.navigationOpen ? 'initial' : 'center',
                                        minHeight: menuItemHeight + 'px',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            justifyContent: 'center',
                                            minWidth: 0,
                                            mr: props.navigationOpen ? 3 : 'auto',
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text} sx={{ opacity: props.navigationOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        )
                    )
                }
            </List>
        </MuiDrawer>
    );
}
