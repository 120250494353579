import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { LoginPage } from './pages/LoginPage';
import { MainPage } from './pages/MainPage';
import { RegistrationPage } from './pages/RegistrationPage';

const globalTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: 'Roboto',
    },
});

export default function App() {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const headerHeight = 50;
    const navigationWidth = 200;

    return (
        <ThemeProvider theme={globalTheme}>
            <CssBaseline enableColorScheme />
            <Layout headerHeight={headerHeight} navigationOpen={navigationOpen} navigationWidth={navigationWidth} setNavigationOpen={setNavigationOpen}>
                <Route exact path='/'>
                    <MainPage headerHeight={headerHeight} navigationOpen={navigationOpen} navigationWidth={navigationWidth} />
                </Route>
                <Route path='/login'>
                    <LoginPage headerHeight={headerHeight} navigationOpen={navigationOpen} navigationWidth={navigationWidth} />
                </Route>
                <Route path='/registration'>
                    <RegistrationPage headerHeight={headerHeight} navigationOpen={navigationOpen} navigationWidth={navigationWidth} />
                </Route>
                <Route path='/forgotPassword'>
                    <ForgotPasswordPage headerHeight={headerHeight} navigationOpen={navigationOpen} navigationWidth={navigationWidth} />
                </Route>
            </Layout>
        </ThemeProvider>
    );
}
