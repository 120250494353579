import { Box } from '@mui/material';
import { Header } from './Header';
import { Navigation } from './Navigation';

export function Layout(props) {
    return (
        <Box>
            <Header headerHeight={props.headerHeight} navigationOpen={props.navigationOpen} setNavigationOpen={props.setNavigationOpen} />
            <Navigation headerHeight={props.headerHeight} navigationOpen={props.navigationOpen} navigationWidth={props.navigationWidth} setNavigationOpen={props.setNavigationOpen} />
            {props.children}
        </Box>
    );
}
