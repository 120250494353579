import { Password } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, CardMedia, Divider, Link, Stack, TextField, useTheme } from '@mui/material';

export function ForgotPasswordPage(props) {
    const theme = useTheme();

    const tempImage = require('../assets/temp.jpg');

    return (
        <Box
            sx={{
                marginLeft: props.navigationOpen ? props.navigationWidth + 'px' : 'calc(' + theme.spacing(7) + ' + 1px)',
                transition: theme.transitions.create('margin-left', {
                    duration: theme.transitions.duration.enteringScreen,
                    easing: theme.transitions.easing.sharp,
                }),
                [theme.breakpoints.up('md')]: {
                    marginLeft: props.navigationOpen ? props.navigationWidth + 'px' : 'calc(' + theme.spacing(8) + ' + 1px)',
                },
            }}
        >
            <Card
                sx={{
                    borderColor: 'white',
                    borderRadius: '10px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    margin: '25px auto',
                    width: '50%',
                    [theme.breakpoints.down('md')]: {
                        width: '75%',
                    },
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                }}
            >
                <CardMedia alt="forgot password logo" component="img" image={tempImage} style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, }} />
                <CardHeader style={{ textAlign: 'center', }} title="Elfelejtett jelszó" />
                <Divider variant="middle" />
                <CardContent>
                    <Stack style={{ textAlign: 'center', marginLeft: '5%', marginRight: '5%', }}>
                        <TextField id="username" label="Felhasználónév/E-mail cím" style={{ marginBottom: 5, marginTop: 5, }} type="text" variant="outlined" />
                        <Button color="info" size="large" startIcon={<Password />} style={{ marginBottom: 5, marginTop: 5, }} variant="contained">Küldés</Button>
                        <Link
                            href="/login"
                            sx={{
                                color: theme.palette.common.white,
                                marginBottom: '5px',
                                marginTop: '5px',
                                transitionDuration: '0.5s',
                                '&:hover': {
                                    color: 'cyan',
                                    textShadow: '0px 0px 5px',
                                    transitionDuration: '0.5s',
                                },
                            }}
                            underline="none"
                        >
                            Bejelentkezés
                        </Link>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
}
