import { ArrowBack, ArrowForward, Autorenew, Cancel, Pause, PlayArrow, Settings } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextareaAutosize,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import { useState } from 'react';
import ReactCountdownClock from 'react-countdown-clock';

export function MainPage(props) {
    const theme = useTheme();

    const defaultAssignment = {
        title: 'Új játék',
        text: 'Folytatáshoz kattints a jobbra mutató gombra!',
        timer: false,
        timerSecond: 0,
    };

    //------------------------------------------------------------useStates---
    const [currentPlayer, setCurrentPlayer] = useState('');
    const [currentRandomPlayer, setCurrentRandomPlayer] = useState('');
    const [currentNumberOfDrinking, setCurrentNumberOfDrinking] = useState(1);
    const [currentAssignment, setCurrentAssignment] = useState(defaultAssignment);
    const [timerPanelShow, setTimerPanelShow] = useState(false);
    const [timerShow, setTimerShow] = useState(true);
    const [timerPaused, setTimerPaused] = useState(true);
    const [timerSecond, setTimerSecond] = useState(0);
    const [newGameDialogOpen, setNewGameDialogOpen] = useState(false);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [players, setPlayers] = useState([]);
    const [currentAssignmentIndex, setOldAssignmentIndex] = useState(0);
    const [oldAssignments, setOldAssignments] = useState([]);
    const [maxNumberOfDrinking, setMaxNumberOfDrinking] = useState(4);

    //------------------------------------------------------------Constants---
    const alarm = require('../assets/roosterAlarm.wav');

    const playerText = '##player';
    const randomPlayerText = '##randomPlayer';
    const numberOfDrinkingText = '##numberOfDrinking';

    const synonymousWords = [
        'cipő',
        'ház',
        'buli',
        'csúnya',
        'szex',
        'pénz',
        'megy',
        'szajha',
        'macska',
        'kutya',
        'vidám',
        'szép',
        'férfi nemiszerv',
        'női nemiszerv',
    ];

    const brandTypes = [
        'sör',
        'autó',
        'whisky',
    ];

    const assignments = [
        {
            title: 'Fekete-Fehér-Igen-Nem',
            text: '"Fekete-Fehér-Igen-Nem" - Ezt a négy szót nem szabad kimondani. Próbáljátok kérdésekkel, ártatlan beszélgetéssel kihúzni egymásból valamelyiket. Aki először kimondja a négy szó bármelyikét, ' + numberOfDrinkingText + ' korttyal kell erősítse figyelmét.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Alsógatya',
            text: 'Akin fekete alsógatya/bugyi van, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Rím',
            text: randomPlayerText + ', válassz egy szót, majd mondjatok egymás után rímeket a választott szóra. Aki megakad, vagy már elhangzott szót ismétel, ' + numberOfDrinkingText + ' kortyot iszik.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Szavazás',
            text: 'Ha időt utazhatnátok, inkább a jövőbe, vagy a múltba mennétek 10 évet? Szavazzatok, majd a kisebbségben levő csapat tagjai igyanak fejenként ' + numberOfDrinkingText + ' kortyot, hátha sikerül az időutazás.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Szinoníma',
            text: 'Mondjatok szinonímákat a(z) "' + synonymousWords[Math.floor(Math.random() * synonymousWords.length)] + '" szóra. Aki elakad, igyon ' + numberOfDrinkingText + ' kortyot. ' + playerText + ' kezdi a kört.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Flört',
            text: 'Aki flörtölt már azért, hogy meghívják egy italra, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Bírunk',
            text: playerText + ', tényleg csipázunk, de igyál ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Szülők',
            text: playerText + ', mi ' + randomPlayerText + ' szüleinek a keresztneve? Ha nem találod el, igyál ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Magányosan',
            text: 'Igyál ' + numberOfDrinkingText + ' kortyot, ha rúgtál már be egyedül.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Sztori',
            text: playerText + ', mondj egy kínos/vicces sztorit. Ha nem megy, igyál ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Matematika',
            text: playerText + ', számolj el 10 másodperc alatt 3-asával 66-ig. Ha nem megy, igyál ' + numberOfDrinkingText + ' kortyot.',
            timer: true,
            timerSecond: 10,
        },
        {
            title: 'Lélegzet elállító',
            text: playerText + ', tartsd vissza a lélegzeted 45 másodpercig. Ha nem megy, igyál ' + numberOfDrinkingText + ' kortyot.',
            timer: true,
            timerSecond: 45,
        },
        {
            title: 'Gyorsaság',
            text: playerText + ' vs. ' + randomPlayerText + ': mondjátok a keresztneveteket visszafelé, amilyen gyorsan csak tudjátok. A vesztes igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Márkák',
            text: 'Soroljatok fel ' + brandTypes[Math.floor(Math.random() * brandTypes.length)] + ' márkákat. Aki nem tud többet, igyon ' + numberOfDrinkingText + ' kortyot. ' + playerText + ' kezdi a kört.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Kő-Papír-Olló',
            text: playerText + ' vs. ' + randomPlayerText + ': játsszatok 3 menetből álló kő-papír-ollót. A vesztes igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'McDonald\'s',
            text: 'Az a játékos, aki utoljára evett valamit a McDonald\'s-ból, csináljon 15 guggolást.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Bottle flip challenge',
            text: playerText + ' vs. ' + randomPlayerText + ': versenyezzetek bottle flip challenge-ben. Akinek nem sikerül 20 próbálkozásból talpára dobni egy műanyag palackot, vagy veszített, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Ittas vezetés',
            text: 'Ha bicikliztél már részegen, igyál ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Póló',
            text: 'Akin nincs póló, az igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Peca',
            text: 'Akinek van horgász vizsgája, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Dacia',
            text: 'Aki vezetett már Dacia-t, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Csak úgy',
            text: 'Aki hülye, az annyit is ér.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Szomjas vagy?',
            text: 'Haladjunk, köcsögök. Mindenki igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Bezártság',
            text: 'Akinek a szobáján nincs ablak, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Magyar Állami Vasutak',
            text: 'Akinek a párja MÁV-os, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Lakhely',
            text: 'Aki nem pécsi, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Lakhely',
            text: 'Aki pécsi, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Főnök Úr',
            text: 'Akinek a főnöke David Hasselhoff-ként fest, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Bevásárlás',
            text: 'Aki sose vesz szatyrot, mert drága, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Szabályzás',
            text: playerText + ', hozz egy szabályt. Ha valaki megszegi, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Település',
            text: 'Mondjatok olyan magyar településeket, amik "fa" végződésűek. Aki elakad, igyon ' + numberOfDrinkingText + ' kortyot. ' + playerText + ' kezdi a kört.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Szavazás',
            text: 'Gyros vagy hamburger? Szavazzunk. Akik a vesztes csapatban vannak, igyanak ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Ajándék',
            text: playerText + ' vs. ' + randomPlayerText + ': mi volt a legrosszabb ajándék, amit valaha adtatok? A többiek döntsék el, melyik volt a legbénább, a vesztes igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Szavazás',
            text: 'Szőke vagy barna? Mindenki szavazzon. Akik a vesztes hajszínt választották, igyanak ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Csak vidáman',
            text: playerText + ', mondj el egy viccet. Ha senki sem nevet, igyál ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Kijózanító',
            text: 'A legjobb alkoholt felszopó kaják... ' + playerText + ' kezd. Az első, aki nem tud újat bedobni, ' + numberOfDrinkingText + ' kortyot iszik.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Humorosan',
            text: playerText + ' vs. ' + randomPlayerText + ': nézzetek mélyen egymás szemébe, és elő a legjobb favicceitekkel. Aki elneveti magát, vagy nem jut eszébe újabb vicc, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Én még soha...',
            text: playerText + ', fejezd be a mondatot: "Én még soha...". Ha a többiek már igen, akkor igyanak ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Témakör',
            text: playerText + ' válasszon témakört (ország, tenger, stb...), majd kezdje el a sort. Az első, aki nem tudja folytatni, ' + numberOfDrinkingText + ' kortyot iszik.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Ne tedd',
            text: 'Dolgok, amiket sose csinálj részegen... ' + playerText + ' kezdi a felsorolást. Aki először kifogy az ötletekből, az iszik ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Lakhely',
            text: 'Aki panel lakásban lakik, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Lakhely',
            text: 'Aki kertes házban lakik, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Nyilvánosan',
            text: 'Aki szexelt már nyilvános helyen, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Dealer',
            text: 'Akinek a legkevesebb pia van a poharában, osszon szét ' + numberOfDrinkingText + ' kortyot ' + playerText + ' és ' + randomPlayerText + ' között.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Van a kistáskámban...',
            text: playerText + ', kezdd el a sort: "Van a kistáskámban...". A következő játékosoknak minden alkalommal fel kell sorolnia az összes előtte elhangzott tartalmat, majd hozzá kell tennie egyet. Aki először hibázik, ' + numberOfDrinkingText + ' korttyal erősítse memóriáját.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Mutogató',
            text: playerText + ', nevezz meg egy szomjas egyént, majd a kiválasztott igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Szavazás',
            text: 'Tengerpart vagy hegyvidék? Szavazzatok, és a vesztesek igyanak ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Szundi',
            text: 'Aki aludt már el nyilvános helyen, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
        {
            title: 'Nincs megállás',
            text: 'Aki aludt már el tömegközlekedésen annyira, hogy tovább haladt céljánál, igyon ' + numberOfDrinkingText + ' kortyot.',
            timer: false,
            timerSecond: 0,
        },
    ];

    const audio = new Audio(alarm);

    //------------------------------------------------------------Functions---
    function SetAssignmentText(text) {
        return text
            .replaceAll(playerText, currentPlayer)
            .replaceAll(randomPlayerText, currentRandomPlayer)
            .replaceAll(numberOfDrinkingText, currentNumberOfDrinking);
    }

    function SetProgram(playerIndex, randomPlayerIndex, assignmentIndex, numberOfDrinking) {
        setCurrentPlayer(players[playerIndex]);
        setCurrentRandomPlayer(players[randomPlayerIndex]);
        setCurrentNumberOfDrinking(numberOfDrinking);
        setCurrentAssignment(assignments[assignmentIndex]);
        setTimerPanelShow(assignments[assignmentIndex].timer);

        if (assignments[assignmentIndex].timer) {
            setTimerPaused(true);
            setTimerSecond(assignments[assignmentIndex].timerSecond);
        }
    }

    function CheckAssignmentExists(assignmentIndex) {
        let result = false;

        for (let i = 0; i < oldAssignments.length; i++) {
            if (oldAssignments[i].assignmentIndex === assignmentIndex) {
                result = true;
                break;
            }
        }

        return result;
    }

    //---------------------------------------------------------------Events---
    function handleBack() {
        if ((oldAssignments.length !== 0) && (currentAssignmentIndex > 0)) {
            setOldAssignmentIndex(currentAssignmentIndex - 1);
            SetProgram(
                oldAssignments[currentAssignmentIndex - 1].playerIndex,
                oldAssignments[currentAssignmentIndex - 1].randomPlayerIndex,
                oldAssignments[currentAssignmentIndex - 1].assignmentIndex,
                oldAssignments[currentAssignmentIndex - 1].numberOfDrinking
            );
        }
    }

    function handleNext() {
        if (oldAssignments.length === assignments.length) {
            setNewGameDialogOpen(true);
            setOldAssignments([]);
            setCurrentAssignment(defaultAssignment);
            setTimerPanelShow(defaultAssignment.timer);

            if (defaultAssignment.timer) {
                setTimerPaused(true);
                setTimerSecond(defaultAssignment.timerSecond);
            }
        }
        else {
            if ((oldAssignments.length !== 0) && (currentAssignmentIndex < oldAssignments.length - 1)) {
                setOldAssignmentIndex(currentAssignmentIndex + 1);
                SetProgram(
                    oldAssignments[currentAssignmentIndex + 1].playerIndex,
                    oldAssignments[currentAssignmentIndex + 1].randomPlayerIndex,
                    oldAssignments[currentAssignmentIndex + 1].assignmentIndex,
                    oldAssignments[currentAssignmentIndex + 1].numberOfDrinking
                );
            }
            else {
                const playerIndex = Math.floor(Math.random() * players.length);
                const numberOfDrinking = Math.floor((Math.random() * maxNumberOfDrinking)) + 1;
                let randomPlayerIndex = Math.floor(Math.random() * players.length);
                let assignmentIndex = 0;

                do {
                    assignmentIndex = Math.floor(Math.random() * assignments.length);
                } while (CheckAssignmentExists(assignmentIndex));

                while (randomPlayerIndex === playerIndex) {
                    randomPlayerIndex = Math.floor(Math.random() * players.length);
                }

                SetProgram(playerIndex, randomPlayerIndex, assignmentIndex, numberOfDrinking);

                oldAssignments.push({
                    playerIndex: playerIndex,
                    randomPlayerIndex: randomPlayerIndex,
                    assignmentIndex: assignmentIndex,
                    numberOfDrinking: numberOfDrinking,
                });

                setOldAssignmentIndex(oldAssignments.length - 1);
            }
        }
    }

    function handleSettings() {
        setSettingsDialogOpen(true);
    }

    function handleTimerPaused() {
        setTimerPaused(!timerPaused);
    }

    function handleTimerRestart() {
        setTimerPaused(true);
        setTimerShow(false);
        setTimeout(function () { setTimerShow(true); }, 0);
    }

    function handleTimerComplete() {
        audio.play();
        setTimerPaused(true);
    }

    function handleNewGameDialogClose() {
        setNewGameDialogOpen(false);
    }

    function handleSettingsDialogClose() {
        setSettingsDialogOpen(false);
    }

    //--------------------------------------------------------------Website---
    return (
        <Box
            sx={{
                marginLeft: props.navigationOpen ? props.navigationWidth + 'px' : 'calc(' + theme.spacing(7) + ' + 1px)',
                transition: theme.transitions.create('margin-left', {
                    duration: theme.transitions.duration.enteringScreen,
                    easing: theme.transitions.easing.sharp,
                }),
                [theme.breakpoints.up('md')]: {
                    marginLeft: props.navigationOpen ? props.navigationWidth + 'px' : 'calc(' + theme.spacing(8) + ' + 1px)',
                },
            }}
        >
            <Card
                style={{
                    borderColor: 'cyan',
                    borderStyle: 'solid',
                    borderRadius: 10,
                    borderWidth: 1,
                    boxShadow: '0px 0px 25px teal',
                    margin: '25px',
                }}
            >
                {/* Main menu panel */}
                <CardActions
                    style={{
                        borderRadius: 10,
                        marginLeft: 10,
                        marginRight: 10,
                        paddingBottom: 10,
                        paddingTop: 10,
                        textAlign: 'center',
                    }}
                >
                    <Grid container spacing={0.5}>
                        <Grid item xs={4}>
                            <IconButton
                                color="error"
                                disabled={players.length > 1 ? false : true}
                                onClick={handleBack}
                                size="large"
                                sx={{
                                    height: '100%',
                                    paddingBottom: '25px',
                                    paddingTop: '25px',
                                    width: '100%',
                                }}
                                variant="contained"
                            >
                                <ArrowBack />
                            </IconButton>
                        </Grid>
                        <Grid item xs={4}>
                            <IconButton
                                color="primary"
                                onClick={handleSettings}
                                size="large"
                                sx={{
                                    height: '100%',
                                    paddingBottom: '25px',
                                    paddingTop: '25px',
                                    width: '100%',
                                }}
                                variant="contained"
                            >
                                <Settings />
                            </IconButton>
                        </Grid>
                        <Grid item xs={4}>
                            <IconButton
                                color="success"
                                disabled={players.length > 1 ? false : true}
                                onClick={handleNext}
                                size="large"
                                sx={{
                                    height: '100%',
                                    paddingBottom: '25px',
                                    paddingTop: '25px',
                                    width: '100%',
                                }}
                                variant="contained"
                            >
                                <ArrowForward />
                            </IconButton>
                        </Grid>
                    </Grid>
                </CardActions>
                <Divider variant="middle" />
                {/* Header panel */}
                <CardHeader title={currentAssignment.title + (oldAssignments.length !== 0 ? ' [' + (currentAssignmentIndex + 1) + '/' + assignments.length + ']' : '')} titleTypographyProps={{ fontWeight: 'bold', textAlign: 'center', }} />
                {/* Main panel */}
                <CardContent>
                    <Grid container>
                        {/* Main text */}
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    component="div"
                                    sx={{
                                        textAlign: 'justify',
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '125%',
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            fontSize: '150%',
                                        },
                                        [theme.breakpoints.up('xl')]: {
                                            fontSize: '175%',
                                        },
                                    }}
                                >
                                    {SetAssignmentText(currentAssignment.text)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Timer panel */}
                        {
                            timerPanelShow &&
                            <Grid container spacing={0.5} style={{ alignItems: 'center', }}>
                                <Grid item xs={4}>
                                    <Box style={{ height: 102, margin: '10px auto', width: 102, }}>
                                        {
                                            timerShow &&
                                            <ReactCountdownClock
                                                color={timerPaused ? "red" : "lime"}
                                                onComplete={handleTimerComplete}
                                                paused={timerPaused}
                                                seconds={timerSecond}
                                                size={100}
                                                weight={10}
                                            />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        color="primary"
                                        onClick={handleTimerPaused}
                                        size="large"
                                        startIcon={timerPaused ? <PlayArrow /> : <Pause />}
                                        sx={{
                                            fontSize: '125%',
                                            paddingBottom: '10px',
                                            paddingTop: '10px',
                                            width: '100%',
                                            [theme.breakpoints.down('sm')]: {
                                                fontSize: '100%',
                                            },
                                        }}
                                        variant="contained"
                                    >
                                        {timerPaused ? 'Indítás' : 'Megállítás'}
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        color="secondary"
                                        onClick={handleTimerRestart}
                                        size="large"
                                        startIcon={<Autorenew />}
                                        sx={{
                                            fontSize: '125%',
                                            paddingBottom: '10px',
                                            paddingTop: '10px',
                                            width: '100%',
                                            [theme.breakpoints.down('sm')]: {
                                                fontSize: '100%',
                                            },
                                        }}
                                        variant="contained"
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    {/* Errors */}
                    {
                        players.length < 2 &&
                        <Alert severity="error" style={{ margin: '20px auto', width: '90%', }}>A játékosok száma nem lehet 2-nél kevesebb.</Alert>
                    }
                </CardContent>
            </Card>
            {/* Alert */}
            <Dialog
                aria-describedby="új játék"
                keepMounted
                onClose={handleNewGameDialogClose}
                open={newGameDialogOpen}
            >
                <DialogTitle style={{ textAlign: 'center', }}>Itt a vége</DialogTitle>
                <Divider variant="middle" />
                <DialogContent>
                    <DialogContentText id="new-game-dialog">
                        Szomjas maradtál? Ha tovább lépsz, a játék újra indul. 🍻
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNewGameDialogClose} variant="outlined">
                        <Cancel />
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Form dialogs */}
            <Dialog
                aria-describedby="beállítások"
                keepMounted
                onClose={handleSettingsDialogClose}
                open={settingsDialogOpen}
            >
                <Grid container style={{ alignItems: 'center', }}>
                    <Grid item xs={8}>
                        <DialogTitle style={{ textAlign: 'center', }}>Beállítások</DialogTitle>
                    </Grid>
                    <Grid item xd={4}>
                        <DialogActions>
                            <Button onClick={handleSettingsDialogClose} variant="outlined">
                                <Cancel />
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
                <Divider variant="middle" />
                <DialogContent>
                    <Stack>
                        <TextField
                            id="drinkingNumber"
                            label="Maximális korty szám"
                            onChange={(e) => {
                                if (e.target.value > 0) {
                                    setMaxNumberOfDrinking(e.target.value);
                                }
                            }}
                            style={{
                                marginBottom: 5,
                                marginTop: 5,
                            }}
                            type="number"
                            value={maxNumberOfDrinking}
                            variant="outlined"
                        />
                    </Stack>
                    <Divider style={{ marginBottom: 10, marginTop: 10, }} variant="middle" />
                    <Stack>
                        <DialogContentText id="players-section">
                            Játékosok:
                        </DialogContentText>
                        <TextareaAutosize
                            onChange={(e) => {
                                setPlayers(e.target.value.split(/\r?\n/));
                            }}
                            placeholder="játékosok"
                            style={{ fontSize: '125%', }}
                            value={players.join('\n')}
                        />
                    </Stack>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
