import { Menu, Search } from '@mui/icons-material';
import { AppBar, Box, IconButton, InputBase, Toolbar, Typography, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export function Header(props) {
    const theme = useTheme();

    function handleNavigationOpen() {
        props.setNavigationOpen(!props.navigationOpen);
    }

    const headerTitle = 'Drinking game';

    return (
        <AppBar style={{ height: props.headerHeight, }}>
            <Toolbar variant="dense">
                <IconButton
                    aria-label="menü kezelés"
                    edge="start"
                    onClick={handleNavigationOpen}
                    size="large"
                    sx={{ mr: 2, }}
                >
                    <Menu />
                </IconButton>
                <Typography
                    component="h1"
                    noWrap
                    sx={{
                        display: {
                            md: 'block',
                            xs: 'none',
                        },
                        flexGrow: 1,
                        fontFamily: 'Comic Sans MS',
                        fontSize: '20px',
                        fontWeight: 'bold',
                    }}
                >
                    {headerTitle}
                </Typography>
                <Box
                    sx={{
                        backgroundColor: alpha(theme.palette.common.white, 0.15),
                        borderRadius: theme.shape.borderRadius,
                        marginLeft: 0,
                        position: 'relative',
                        transitionDuration: '0.5s',
                        width: '100%',
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.common.white, 0.25),
                            transitionDuration: '0.5s',
                        },
                        [theme.breakpoints.up('md')]: {
                            marginLeft: theme.spacing(1),
                            width: 'auto',
                        },
                    }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',
                            padding: theme.spacing(0, 2),
                            pointerEvents: 'none',
                            position: 'absolute',
                        }}
                    >
                        <Search />
                    </Box>
                    <InputBase
                        inputProps={{ 'aria-label': 'keresés...', }}
                        placeholder="keresés..."
                        sx={{
                            '& .MuiInputBase-input': {
                                padding: theme.spacing(1, 1, 1, 0),
                                paddingLeft: 'calc(1em + ' + theme.spacing(4) + ')',
                                transition: theme.transitions.create('width'),
                                width: '100%',
                                [theme.breakpoints.up('md')]: {
                                    width: '12ch',
                                    '&:focus': {
                                        width: '20ch',
                                    },
                                },
                            },
                        }}
                    />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
